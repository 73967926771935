import { createBlitCommand } from '../blit';

import frag from './fire.frag';

/**
 * @param {import('regl').Regl} regl
 */
export function createFireCommand(regl) {
  const blit = createBlitCommand(regl);

  let clicked = 0;
  window.addEventListener('click', () => {
    clicked = true;
  });

  const BLACK = regl.texture(Array.from(Array(4)).map(() => [1, 1, 1]));
  const cmd = regl({
    frag,
    uniforms: {
      uTime: regl.prop('time'),
      uDuration: 10,
      uStart: regl.prop('start'),
      uTexture: BLACK,
    },
    depth: { enable: false },
    blend: {
      enable: true,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
      },
    },
    primitive: 'triangle fan',
  });

  let start = 0;
  const draw = () => {
    const cancelable = regl.frame(({ time }) => {
      if (clicked) {
        clicked = false;
        start = time;
      }
      // regl.clear({ color: [1, 0, 0, 0], depth: 1 });
      try {
        blit(() => cmd({ time, start }));
      } catch (e) {
        console.error(e);
        if (cancelable) {
          cancelable.cancel();
        }
      }
    });
  };
  return { draw };
}
