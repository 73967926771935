import vert from './blit.vert';

/**
 * @param {import('regl').Regl} regl
 * @param {string|undefined}
 */
export function createBlitCommand(regl, frag) {
  return regl({
    vert,
    attributes: { position: [-4, -4, 0, 4, 4, -4] },
    depth: { enable: false },
    count: 3,
    blend: {
      enable: true,
      func: {
        srcRGB: 'src alpha',
        srcAlpha: 'src alpha',
        dstRGB: 'one minus src alpha',
        dstAlpha: 'one minus src alpha',
      },
    },
    primitive: 'triangle fan',
    ...(frag ? { frag } : {}),
  });
}
