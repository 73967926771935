<template lang="pug">
.empty
</template>

<script>
import { onMounted } from 'vue';
import createREGL from 'regl';

import { createFireCommand } from '@/commands/fire';

export default {
  setup() {
    onMounted(async () => {
      const regl = createREGL();
      const { draw } = createFireCommand(regl);
      window.requestAnimationFrame(() => draw());
    });
  },
};
</script>

<style lang="stylus">
body
  background #fff
</style>
